import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,

  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/Stavien-mobile.jpg"
import LuteinComplexTablet from "./../../images/Stavien-tablet.jpg"
import LuteinComplexDesktop from "./../../images/Stavien-desktop.jpg"
import LuteinComplexXL from "./../../images/Stavien-desktop-xl.jpg"
import Feature1 from "./../../images/sta-knee-joint.jpg"
import Feature2 from "./../../images/sta-hyaluronic-acid.jpg"
import Feature3 from "./../../images/sta-knee-pain.jpg"
import Feature4 from "./../../images/omega-3-acids.jpg"
import FeatureLicaps from "./../../images/LICAPS-GUARANTEE.jpg"


const StavienPageEn = () => (
  <Layout backButton={true} enSite pageInfo={{ pageName: "Stavien-en" }}>
    <Seo title="Stavien - Hyaluronic acid for easier movement" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/en">HOME</Link>
            </li>
            <Breadcrumb.Item active>STAVIEN</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Stavien"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Stavien
          </h5>
          <h1 className="display-4 font-light">
            Hyaluronic acid
            <br />
            for easier movement
          </h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">Stavien</h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Hyaluronic acid
            <br />
            for easier movement
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Everything you need for nourishment of the joints is included in
            Stavien, with proven anti-inflammatory action.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/ena/stavien-envh1005"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Buy online at <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Available in pharmacies across the country</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>solvent free</li>
                <li>preservatives free</li>
                <li>gluten free</li>
                <li>sugar free</li>
                <li>GMO-free</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Ease of movement</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Ease of movement"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Stavien is extremely effective as an aid in joint wear, pain relief,
            stiffness, osteoarthritis, rheumatoid arthritis and injuries.
          </p>
          <p>
            Stavien is recommended for active athletes, people engaged in heavy
            physical work, as well as under heavy load. Even if you are not one
            of them, the joints wear out over time, which leads to various
            problems with them and the additional intake of hyaluronic acid and
            other anti-inflammatory substances would have only a positive
            effect.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Nourishment of the articular cartilage
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="Nourishment of the articular cartilage"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            The main ingredient in Stavien is hyaluronic acid. It makes up the
            intercellular substance and is a major component of synovial fluid.
            It protects the articular cartilage and synovial membrane from
            damage. Hyaluronic acid is important for maintaining normal function
            and integrity of joints and bones.
          </p>
          <p>
            Until recently, the only way to nourish our joints with hyaluronic
            acid was painful injections directly into the joint. Fortunately,
            this is no longer the only way. Stavien is in the form of capsules
            with liquid content, taken orally.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Strong anti-inflammatory capabilities</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Strong anti-inflammatory capabilities"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Fish oil in Stavien helps reduce inflammation by acting on the
            so-called cytokines - mediators of inflammation.
          </p>
          <p>
            Turmeric root extract contains potassium, iron, magnesium, vitamin
            B6, and its main ingredient is curcumin. Curcumin has a powerful
            antioxidant effect and is a natural remedy for arthritis and
            dementia.
          </p>
          <p>
            Astaxanthin is the carotenoid with the most powerful antioxidant
            action - 10 times stronger than that of Vitamin A and 100 to 500
            times stronger than that of Vitamin E. It has anti-inflammatory
            effects, especially on the joint and muscular system.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">
            Enriched with Omega-3 fatty acids for a healthy body
          </h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Enriched with Omega-3 fatty acids for a healthy body"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Stavien also contains Omega-3 fatty acids in the form of fish oil.
            They are necessary for the normal functioning of the cell and help
            protect the body from developing a number of diseases. They also
            support the cardiovascular system and help maintain healthy
            cholesterol levels. Omega-3 fatty acids are a vital component that
            the human body cannot produce on its own.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Guarantee for quality and safety</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={FeatureLicaps}
            alt="Guarantee for quality and safety"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Stavien capsules are manufactured at Capsugel's plants in Colmar,
            France, using the patented technology for using liquid plant
            extracts in hard capsules.
          </p>
          <p>
            This technology eliminates the heat treatment of substances, which
            allows to preserve their useful properties and ensures rapid and
            complete absorption by the body. Compared to solid forms such as
            tablets, the ingredients in Stavien capsules are released very
            quickly because they are pre-dissolved. They are completely natural
            and free of solvents, preservatives, gluten, sugar and GMOs.
          </p>
          <p>
            Read more in the <Link to="/en/technology">“Technology”</Link>{" "}
            section.
          </p>
        </Col>
      </Row>

      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Additional information</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Contents
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Hyaluronic Acid</strong> 20 mg
                  </p>
                  <p>
                    <strong>Turmeric extract</strong> (Curcuma longa, Rhiz.
                    extr., curcumin) 75 mg
                  </p>
                  <p>
                    <strong>Astaxanthin</strong> (Astaxanthine 50 mg/g) 10 mg
                  </p>
                  <p>
                    <strong>Fish oil</strong> (Fish oil, 150 mg/g EPA, 100 mg/g
                    DHA) 325 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Intake
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Take 2 capsules Stavien daily after meals, no less than 3
                    months, without interruption.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Contraindications
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    None identified. Not recommended for people allergic to fish
                    products. Stavien is a dietary supplement and does not cause
                    irritation and hypersensitivity, and can be taken for a long
                    period. Stavien can be taken alone or in combination with
                    medication prescribed by your doctor.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Effects
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Until recently, hyaluronic acid had to be injected directly
                    into the joints or under the skin because the digestive
                    system could not absorb it in its natural state. Thanks to
                    Stavien's innovative technology, it is now possible to take
                    the valuable for the function of the joints, hyaluronic acid
                    in the form of capsules. Hyaluronic acid in Stavien has low
                    molecular weight, which allows its complete absorption after
                    oral administration.
                  </p>
                  <p>
                    Hyaluronic acid is an important component of articular
                    cartilage and synovial fluid. It is synthesized by cartilage
                    cells and the synovial membrane. It protects the articular
                    cartilage and synovial membrane from damage. Hyaluronic acid
                    plays an essential role in healthy joints. It is essential
                    for the proper functioning of the synovial fluid, which
                    maintains the normal function and integrity of both the
                    joints and the underlying bones. It allows the physiological
                    functions and elastic properties of the joints affected by
                    osteoarthritis to be restored. The addition of hyaluronic
                    acid restores the balance between increased breakdown
                    products of metabolism and its own delayed synthesis,
                    disrupted due to the aging process of tissues and the body
                    as a whole. This relieves pain and facilitates movement in
                    the affected joint. The physiological functions of synovial
                    fluid lubrication and the elastic properties of the joints
                    are restored. The joint is again able to act as a shock
                    absorber.
                  </p>
                  <p>How do the active ingredients in Stavien work?</p>
                  <p>
                    Hyaluronic acid is a carbohydrate (mucopolysaccharide) that
                    is found naturally in most cells of the human body in
                    different amounts. The highest concentration is in the
                    synovial fluid (the one found in the joint cavities) and in
                    the connective tissue. It is found in the bones and
                    cartilage, maintaining the plasticity and extensibility of
                    the tissues. Hyaluronic acid is essential for the health and
                    extensibility of joints and tendons. The joints are covered
                    with a synovial membrane, which secretes synovial fluid,
                    which is viscous and creates conditions for elasticity and
                    movement without rubbing the joint surfaces. It carries
                    nutrients to the cartilage and also disposes of waste
                    products. Because cartilage has no blood vessels, hyaluronic
                    acid performs similar functions.
                  </p>
                  <p>
                    Fish oil is standardized in content and belongs to the group
                    of essential omega-3 fatty acids for the human body. It is a
                    major component in the phospholipids of plasma membranes of
                    neurons, participates in the transport of potassium, reduces
                    the level of cytokines, which are increased in inflammatory
                    processes. Regulates cellular activity, maintains the
                    functional state of blood vessels. Polyunsaturated fatty
                    acids from the omega-3 group support the functions of
                    articular cartilage, provide ease and freedom of movement of
                    joints and tendons.
                  </p>
                  <p>
                    Turmeric is a plant of the ginger genus, actively used in
                    traditional medicine. The main ingredient in turmeric
                    extract is curcumin, which has a strong antioxidant
                    activity. Studies have shown that curcumin affects the
                    functions of neutrophils and inhibits their aggregation in
                    inflammatory processes.
                  </p>
                  <p>
                    Astaxanthin is a carotenoid with antioxidant activity higher
                    than that of other carotenoids. Research shows its ability
                    to favorably affect the activity of the cardiovascular,
                    musculoskeletal and immune systems. Supports and maintains
                    the natural anti-inflammatory functions of the body and
                    especially the joint and muscular system.
                  </p>

                  <p>
                    <a
                      href="http://vitaherb.bg/leaflets/Stavien-Quality-Certificate.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Product quality certificate
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default StavienPageEn
